<template lang="pug">
    article#yachthouse
        img(:src="yachthouse[galeriaAtivo].bg.img", :alt="yachthouse[galeriaAtivo].titulo", :style="yachthouse[galeriaAtivo].bg.style").bgAGt
        div.wrapper.flex
            DescricaoAGT(:conteudo="descricaoConteudo")
            GaleriaAGT(:galeria="yachthouse", :galeriaAtivo.sync="galeriaAtivo")
</template>

<script>
import DescricaoAGT from '@/components/DescricaoAGt/DescricaoAGt.vue'
import GaleriaAGT from '@/components/GaleriaAGt/GaleriaAGt.vue'

export default {
    components: {
        DescricaoAGT,
        GaleriaAGT
    },
    data() {
        return {
            galeriaAtivo: 0,
            descricaoConteudo: {
                titulo: 'O Yachthouse', 
                texto: 'By Pininfarina'
            },
            yachthouse: [
                {
                    titulo: "INOVAÇÃO",
                    subtitulo: "Se não fosse fora do comum, não seria Yachthouse.\n",
                    texto: "Com inovação arquitetônica e traços elegantes, o empreendimento é rico em dinamismo e movimento, onde a base, representada por uma quilha, aponta em direção ao mar. Os apartamentos, localizados em duas torres, contam com paredes de vidro e uma vista surpreendente. A área de lazer, distribuída em dois pavimentos, oferece várias opções de entretenimento para o melhor do seu bem-estar.",
                    img: require('@/assets/images/yachthouse/yachthouse_inovacao.jpg'),
                    bg: {
                        img: require('@/assets/images/yachthouse/yachthouse_inovacao.jpg'),
                        style: {}
                    }
                },
                {
                    titulo: "DESIGN",
                    subtitulo: "Desenho surpreendente em um dos destinos mais desejados do Brasil.\n",
                    texto: "Inspirado no luxuoso universo náutico, o empreendimento foi planejado para contemplar a paixão pelo mar aliada à sofisticação e à qualidade, sempre rigorosamente aplicadas pela construtora. Um conceito singular por ser construído junto à Marina Tedesco, na Barra Sul, com vista magnífica para o mar, o verde da Mata Atlântica e o Rio Camboriú.\n",
                    img: require('@/assets/images/yachthouse/yachthouse_design.jpg'),
                    bg: {
                        img: require('@/assets/images/yachthouse/yachthouse_design.jpg'),
                        style: {}
                    }
                },
                {
                    titulo: "INFRAESTRUTURA",
                    subtitulo: "Tudo o que você precisa para aproveitar paisagens de tirar o fôlego.",
                    texto: "Um lugar de potencial incomparável. Esta região privilegiada é um paraíso para quem quer viver o melhor da vida. A facilidade de se locomover, seja por terra, mar ou ar, fazem do litoral norte de Santa Catarina um lugar singular propício para o lazer e para os negócios.\n",
                    img: require('@/assets/images/yachthouse/yachthouse_infraestrutura.jpg'),
                    bg: {
                        img: require('@/assets/images/yachthouse/yachthouse_infraestrutura.jpg'),
                        style: {}
                    }
                },
                {
                    titulo: "LOCALIZAÇÃO",
                    subtitulo: "Onde todos querem estar, viver e aproveitar.",
                    texto: "Situado na Barra Sul, o bairro mais nobre de Balneário Camboriú, onde as águas doces do rio se encontram com as salgadas do mar, está o Yachthouse by Pininfarina. Emoldurada pela natureza, a região apresenta os mais diferentes atrativos: do simples passeio nas areias da praia, passando pela alta gastronomia, ao lazer náutico. Um misto de urbanização e belas paisagens que conferem à Barra Sul ares paradisíacos.\n",
                    img: require('@/assets/images/yachthouse/yachthouse_localizacao.jpg'),
                    bg: {
                        img: require('@/assets/images/yachthouse/yachthouse_localizacao.jpg'),
                        style: {}
                    }
                },
            ]
        }
    },
}
</script>

<style lang="stylus" scoped src="./Yachthouse.styl"></style>